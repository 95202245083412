import Card from "react-bootstrap/Card";
import { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";

const VideoLibrary = ({ labels, videos, participantDetails }) => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState({});

  const handleShow = (e) => {
    setFile(getVideoFileName(e));
    setShow(true);
  };
  const handleClose = () => {
    setFile({});
    setShow(false);
  };

  const getVideoFileName = (data) => {
    const planId = participantDetails?.extPlanId;
    return data?.link[planId] || data?.link["ALL"];
  };

  const filterVideos = (videos) => {
    const planId = participantDetails?.extPlanId;
    return videos.filter((e) => e?.link[planId] || e?.link["ALL"]);
  };

  return (
    <>
      <div className="section-two py-2">
        <div className="header mb-4 py-1">
          {labels?.learningCenter?.videoLibrary}
        </div>

        {filterVideos(videos).map((e, index) => {
          return (
            <Fragment key={e.id}>
              <Card
                style={{
                  width: "399px",
                  height: "225px",
                  marginRight: index % 2 === 0 ? "48px" : "",
                }}
                className="video-card d-inline-block mb-4"
                onClick={() => handleShow(e)}
              >
                <Card.Body className="p-0">
                  <iframe
                    title={`Video-${index}`}
                    src={getVideoFileName(e)}
                    id="iframeId"
                    style={{
                      width: "399px",
                      height: "225px",
                      pointerEvents: "none",
                    }}
                  ></iframe>
                </Card.Body>
              </Card>
              <small>{e.description}</small>
            </Fragment>
          );
        })}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        id="iframeModal"
        contentClassName="align-items-center"
      >
        <Modal.Body>
          <>
            <iframe
              width="960"
              height="540"
              src={file}
              title={file}
              className="d-block"
            ></iframe>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoLibrary;
